import React from 'react';
import DoctorsBioGrid from './DoctorsBioGrid';
import DoctorsModalGrid from './DoctorsModalGrid';
import DuotoneBumper from './DuotoneBumper';
import IngredientOverview from './IngredientOverview';
import IngredientComparisonCarousel from './IngredientComparisonCarousel';
import ImageCarousel from './ImageCarousel';
import CertificationsList from './CertificationsList';
import CertificationsGrid from './CertificationsGrid';
import Testimonial from './Testimonial';
import TestimonialCarousel from './TestimonialCarousel';
import RichTextColumns from './RichTextColumns';
import PressTestimonials from './PressTestimonials';
import PressGrid from './PressGrid';
import InstagramGrid from './InstagramGrid';
import FullWidthForm from './FullWidthForm';
import CalloutCollection from './CalloutCollection';
import HorizontalRule from './HorizontalRule';
import MediaWithRichText from './MediaWithRichText';
import FullWidthMedia from './FullWidthMedia';
import FullWidthReviews from './FullWidthReviews';
import HeroBlog from './HeroBlog';
import BlogAttribution from './BlogAttribution';
import BlogBodyRichText from './BlogBodyRichText';
import BlogSectionHeader from './BlogSectionHeader';

export default ({author, category, components = [], date}) => (
	<React.Fragment>
		{components.map((component, index) => {
			switch (component._type) {
				case 'heroBlog':
					return (
						<HeroBlog
							index={index}
							key={component._key}
							category={category.title}
							{...component}
						/>
					);
				case 'blogAttribution':
					return (
						<BlogAttribution
							index={index}
							key={component._key}
							author={author}
							date={date}
							{...component}
						/>
					);
				case 'blogBodyRichText':
					return (
						<BlogBodyRichText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'blogSectionHeader':
					return (
						<BlogSectionHeader
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'duotoneBumper':
					return (
						<DuotoneBumper
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'testimonial':
					return (
						<Testimonial
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'testimonialCarousel':
					return (
						<TestimonialCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'ingredientOverview':
					return (
						<IngredientOverview
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'ingredientComparisonCarousel':
					return (
						<IngredientComparisonCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'doctorsBioGrid':
					return (
						<DoctorsBioGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'doctorsModalGrid':
					return (
						<DoctorsModalGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'imageCarousel':
					return (
						<ImageCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'certificationsList':
					return (
						<CertificationsList
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'certificationsGrid':
					return (
						<CertificationsGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'richTextColumns':
					return (
						<RichTextColumns
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'pressTestimonials':
					return (
						<PressTestimonials
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'pressGrid':
					return (
						<PressGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'instagramGrid':
					return (
						<InstagramGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'fullWidthForm':
					return (
						<FullWidthForm
							index={index}
							key={component._key}
							{...component.form}
						/>
					);
				case 'calloutCollection':
					return (
						<CalloutCollection
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'horizontalRule':
					return (
						<HorizontalRule
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'mediaWithRichText':
					return (
						<MediaWithRichText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'fullWidthMedia':
					return (
						<FullWidthMedia
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'fullWidthReviews':
					return (
						<FullWidthReviews
							index={index}
							key={component._key}
							{...component}
						/>
					);
				default:
					return null;
			}
		})}
	</React.Fragment>
);
