import React from 'react';
import Link from '../components/Link';
import BlockContent from '@sanity/block-content-to-react';
import format from 'date-fns/format';

export default ({
	author = {},
	date,
	reviewer = '',
	factChecker = '',
}) => {
	return (
		<div className="grid-container contained">
			<div className="row align--center mt6 mb5">
				<div className="col c12 c10--lg">
					<div className="df aic">
						{ author.image && author.image.url &&
							<div className="dib image blog-author__photo mr3">
								<div className="blog-author__photo-bg image size--1x1">
									<picture>
										<img alt={`${author.name} Profile Photo`} src={`${author.image.url}?w=300&amp;auto=format`}></img>
									</picture>
								</div>
							</div>
						}
						<div className="dib rich-text">
							<p className="mt0 mb0">
								<strong>Written by </strong>
								<Link aria-label={author.name} url={`/blog/author/${author.slug}`}>{author.name}</Link> on {format(
									new Date(date),
									'MMMM d, yyyy',
								)}
							</p>
							{reviewer &&
								<p className="mt0 mb0"><strong>Medically reviewed by</strong> {reviewer}</p>
							}
							{factChecker &&
								<p className="mt0 mb0"><strong>Fact checked by</strong> {factChecker}</p>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
