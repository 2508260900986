import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';

export default ({
	category,
	title,
	titleWidth = 'c10',
	subtitle,
	subtitleWidth = 'c10',
}) => (
	<div className="blog-hero pt4 pb6 pt12--lg pb12--lg mt0 mb6">
		<div className="grid-container contained">
			<div className="row align--center">
				<div
					className={classNames('col', {
						'c10--lg': titleWidth === 'c10',
						'c8--lg': titleWidth === 'c8',
					})}
				>
					<button title={category} aria-label={category} className="no-hover button--pill--secondary">
						{category}
					</button>
					<h1 className="mt5 mb3 serif--xl serif--xxxl--md">{title}</h1>
					{subtitle && <h2 className="sans--md sans--lg--lg fw--700">{subtitle}</h2>}
				</div>
			</div>
		</div>
	</div>
);
