import React from 'react';

export default ({
	header,
}) => (
	<div className="grid-container contained">
		<div className="row align--center">
			<div className="col c12 c10--lg">
				<h3 className="mt5 mb5 serif--lg serif--xxl--md">{header}</h3>
			</div>
		</div>
	</div>
);
