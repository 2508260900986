import React from 'react';
import Seo from '../components/Seo';
import Link from '../components/Link';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import BlogComponentList from '../components/BlogComponentList';

export default ({pageContext, location}) => {
	const {seo = {}, components = [], name, link, role, image, bio, hideFromSearchEngine} = pageContext;

	const metaTitle = seo.metaTitle || `${pageContext.name} - Genexa`;
	const openGraphTitle =
		seo.openGraphTitle || `${pageContext.name} - Genexa`;
	const twitterTitle = seo.twitterTitle || `${pageContext.name} - Genexa`;

	return (
		<React.Fragment>
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={seo.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={seo.twitterImage}
				pathname={location.pathname}
				noIndexNoFollow={hideFromSearchEngine}
			/>

			<div className="grid-container contained">
				<div className="row align--center">
					<div className="col c12 c10--md">
						<div className="rich-text line-break tc">
							<h1 className="mt5 mb3 serif--xl serif--xxxl--md">{name}</h1>
						</div>
					</div>
				</div>

				<div className="row align--center">
					<div className="col c12 c10--md c8--lg">
						<div className={`rich-text line-break df fdc aic`}>
							{role && <strong>{role}</strong>}
							<Link {...link}  title={name}/>

							{image && image.url &&
								<div className="image blog-author__photo--lg mt5 mb5">
									<div className="blog-author__photo-bg image size--1x1">
										<picture>
											<source
												srcSet={`${image.url}?w=600&auto=format&q=75`}
												media="(min-width: 1000px)"
											/>
											<source
												srcSet={`${image.url}?w=480&auto=format&q=75`}
												media="(min-width: 600px)"
											/>
											<img
												alt={image.alt?image.alt:name}
												src={`${image.url}?w=300&auto=format`}
											/>
										</picture>
									</div>
								</div>
							}
						</div>
					</div>
				</div>

				<div className="row align--center">
					<div className="col c12 c10--md c8--lg">
						<div className="author__bio rich-text line-break tc">
							<BlockContent
								blocks={bio}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
